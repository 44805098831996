import * as React from "react"
import SEO from "components/seo"
import { PageContextT } from "../types/Common"
import Layout from "../components/organisms/Layout"
import Container from "../components/atoms/Container"
import MainSupportCategories from "../components/molecules/MainSupportCategories"
import SupportQuickContacts from "../components/molecules/SupportQuickContacts"
import PageBuilderWrapper from "../components/PageBuilder/PageBuilderWrapper"
import { useTranslation } from "react-i18next"
import { ContactForm } from "components/PageBuilder/Components/ContactForm"

type Props = {
  pageContext: PageContextT
}

const IndexPage: React.ComponentType<Props> = (props: Props) => {
  const {
    pageContext: {
      page,
      mainHeaderLinks,
      otherHeaderLinks,
      navigationLinks,
      footerSections,
      socialLinks,
      commonLinks,
      supportCategories,
      configurations,
      uploadedFiles,
      mobileHeaderLinks,
      contactForms,
      language,
    },
  } = props

  const mainSupportCategoriesItems = supportCategories.filter(
    c => c.support_category === null
  )
  const { i18n } = useTranslation()

  return (
    <Layout
      mainHeaderLinks={mainHeaderLinks}
      otherHeaderLinks={otherHeaderLinks}
      navigationLinks={navigationLinks}
      footerSections={footerSections}
      socialLinks={socialLinks}
      commonLinks={commonLinks}
      configurations={configurations}
      mobileHeaderLinks={mobileHeaderLinks}
      breadCrumbs={[]}
      localizedPage={{
        id: 1,
        title: "Support",
        url: language === "en" ? "/podpora" : "/en/support",
        isHomepage: false,
        order: 1,
        page: null,
        section: null,
        parent: null,
      }}
    >
      <SEO
        title={page.SEO?.title || page.title}
        description={page.SEO?.description}
      />
      {page.pageBuilderContentLocation === "beforeTemplate" && (
        <PageBuilderWrapper text={page.text} uploadedFiles={uploadedFiles} />
      )}

      <Container className={"sm:mt-32 mt-24 mb-20"}>
        <div className="flex w-full flex-wrap">
          <div className="w-full md:w-full lg:w-8/12 sm:pr-0 mb-4">
            <MainSupportCategories
              supportCategories={mainSupportCategoriesItems}
            />
          </div>
          <div className="w-full md:w-full lg:w-4/12 sm:pr-0 md:px-0 lg:pl-4 xl:pl-8 lg:mt-0 mt-8">
            <SupportQuickContacts configurations={configurations} />
          </div>
        </div>
      </Container>
      {page.pageBuilderContentLocation === "afterTemplate" && (
        <PageBuilderWrapper text={page.text} uploadedFiles={uploadedFiles} />
      )}
      <ContactForm
        configurations={configurations}
        uploadedFiles={uploadedFiles}
        socialLinks={configurations}
        componentProps={{
          contactForm: language === "en" ? 42 : 41,
        }}
        contactForms={contactForms}
      />
    </Layout>
  )
}

export default IndexPage

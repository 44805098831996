import React from "react"
import { SupportCategoriesT } from "types/Common"
import Grid from "../PageBuilder/Common/Grid"
import { ProductDetail } from "../atoms/ProductDetail"
import { useTranslation } from "react-i18next"

type PropsT = {
  supportCategories: SupportCategoriesT
}

const MainSupportCategories: React.FunctionComponent<PropsT> = ({
  supportCategories,
}) => {
  const { t } = useTranslation()
  return (
    <Grid sm={2} gap={"2rem"}>
      {supportCategories.map((category, index) => {
        return (
          <ProductDetail
            key={index}
            link={category.slug}
            buttonTitle={t("findOutMore")}
            product={category}
            iconSize={"text-4xl"}
          />
        )
      })}
    </Grid>
  )
}

export default MainSupportCategories

import React from "react"
import { ConfigurationT } from "types/Common"
import ConfigurationWithIcon from "../molecules/ConfigurationWithIcon"
import { getConfiguration } from "../../utilities/configuration"
import { useTranslation } from "react-i18next"

type PropsT = {
  configurations: [ConfigurationT]
}

const SupportQuickContacts: React.FunctionComponent<PropsT> = ({
  configurations,
}) => {
  const { t } = useTranslation()
  return (
    <div className={"bg-quantcomBlue xl:px-10 sm:px-8 px-6 py-8"}>
      <h2 className={"text-white font-bold lg:text-4xl text-3xl mb-5"}>
        {t("247support")}
      </h2>
      <div className={"mb-5"}>
        <ConfigurationWithIcon
          iconColor={"text-white"}
          icon={"mail"}
          value={getConfiguration(configurations, "SUPPORT_EMAIL")}
          textClasses={"text-white sm:text-2xl text-lg font-light"}
        />
      </div>

      <div className={"mb-5"}>
        <ConfigurationWithIcon
          iconColor={"text-white"}
          icon={"telephone"}
          value={getConfiguration(configurations, "SUPPORT_LANDLINE_1")}
          textClasses={"text-white sm:text-2xl text-lg font-light"}
        />
      </div>

      <div className={"mb-8"}>
        <ConfigurationWithIcon
          iconColor={"text-white"}
          icon={"telephone"}
          value={getConfiguration(configurations, "SUPPORT_LANDLINE_2")}
          textClasses={"text-white sm:text-2xl text-lg font-light"}
        />
      </div>

      <div>
        <div className={"text-white font-light sm:text-lg mb-3"}>
          {t("supportUnavailable")}
        </div>
        <ConfigurationWithIcon
          iconColor={"text-white"}
          icon={"phone"}
          value={"GSM: " + getConfiguration(configurations, "SUPPORT_GSM")}
          textClasses={"text-white sm:text-2xl text-lg font-light"}
        />
      </div>
    </div>
  )
}

export default SupportQuickContacts
